import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css'
import { login } from '../../actions/auth/login';
import { genesis } from '../../actions/auth/genesis';



function Genesis(props) {
    const [mounted, setMounted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");


    function handleSubmit(event) {
        event.preventDefault();
        dispatch(genesis(phoneNumber))
    }
    console.log(props)

    if (props.genesis.done) {
        navigate("/verify-code");
    }
    useEffect(() => {
        if (mounted && props.login.done === false) {
            if (Object.keys(props.login.error).length !== 0) {
                if (props.login.error.response.data) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: props.login.error.response.data,
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Bir şeyler ters gitti.",
                        footer: '<a href="">Why do I have this issue?</a>'
                    })
                }

            }
        }
        setMounted(true);
    }, [props.login])


    return (
        <div className='row'>

            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                <img className='mt-4 d-flex justify-content-start ms-4' src="./assets/images/logo_splash.svg" alt="" />



                <div className="container mt-4 text-start">
                    <div>

                    </div>
                    <div className='row'>

                        <div className="col-sm-0 col-md-0 col-lg-2 col-xl-2"></div>
                        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 pe-2 ps-2">
                            <h1 className='text-primary text-start mt-4'>Kayıt ol</h1>
                            <form onSubmit={handleSubmit} className="input-group">

                                <label htmlFor="basic-url" className="form-label mt-8">Telefon Numarası</label>
                                <div className="input-group mb-3">
                                    <PhoneInput
                                        containerStyle={{ width: "100%" }}
                                        required
                                        id="phoneNumber"
                                        type="text"
                                        placeholder="Telefon numaranız"
                                        name="phoneNumber"
                                        country={"tr"}
                                        inputStyle={{ width: `100% !important!` }}
                                        enableSearch={false}
                                        value={phoneNumber}
                                        onChange={(e) => { setPhoneNumber(e) }}
                                    />
                                </div>

                                <div className='row w-100'>
                               
                                    <div className="col d-flex justify-content-end">
                                        <Link to="/forgot-password" className='text-primary'>Şifremi Unuttum</Link>
                                    </div>
                                </div>
                                <div className='d-grid gap-2 w-100 mt-4'>
                                    {props.login.spinner ?
                                        <button type='submit' className='btn btn-primary' disabled>
                                            <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span>
                                            Lütfen bekleyin
                                        </button>
                                        :
                                        <button type='submit' className='btn btn-primary'>Kayıt Ol</button>
                                    }
                                </div>

                                <p className='h2hr'><span className='h2hrspan text-muted'>Zaten bir hesabım var</span></p>

                                <div className="d-grid gap-2 w-100 mt-4">
                                    {/* <Link to="./register" className="btn btn-outline-primary">Create account</Link> */}
                                    <Link to="/login" className='btn btn-outline-primary'>Giriş Yap</Link>
                                </div>

                            </form>
                        </div>
                        <div className="col-sm-0 col-md-0 col-lg-2 col-xl-2"></div>
                    </div>
                </div>
            </div>
            <div className='d-none d-lg-block col-lg-7 col-xl-7'>
                <div className="bg-img">
                    <div className='mt-9 bg-content'>
                        <h2 className='text-light text-start'>Project Alzheimer</h2>
                        <p className='text-light text-start mt-4'></p>
                        <p className='text-light text-start'></p>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Genesis);