export function ConvertDate(isoTarih) {
    const aylar = [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];

    const gunler = [
        'Pazar', 'Pazartesi', 'Salı', 'Çarşamba',
        'Perşembe', 'Cuma', 'Cumartesi'
    ];

    const tarih = new Date(isoTarih);
    const gun = tarih.getUTCDate();
    const ay = aylar[tarih.getUTCMonth()];
    const haftaninGunu = gunler[tarih.getUTCDay()];
    const saat = tarih.getUTCHours();
    const dakika = tarih.getUTCMinutes().toString().padStart(2, '0');

    return `${gun} ${ay} ${haftaninGunu} ${saat}:${dakika}`;
}




export function FormatZuluTime(zuluTime) {
    // Zulu zamanını Date nesnesine dönüştürme
    const date = new Date(zuluTime);
  
    // Kullanıcının yerel diline ve zaman dilimine göre tarihi formatlama
    const options = {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: 'numeric', minute: '2-digit', second: '2-digit',
      hour12: false // 24 saat formatını kullan
    };
  
    // Formatlanmış tarihi döndür
    return new Intl.DateTimeFormat('tr-TR', options).format(date);
  }
  