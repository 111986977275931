import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import PatientStatistic from '../dashboard/patientStatistic'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

function GameCard(props) {
    console.log("gamecardprops")
    const tags = props.tags.split(',').map(tag => tag.trim());
    return (
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
            <div className="card">
                <div className="card-header">
                    {props.name}
                </div>
                <div className="card-body">
                    <h4>{props.detail}</h4>
                    <hr />
                    {tags.map((tag, index) => (
                        <span key={index} className="badge bg-secondary m-1">{tag}</span>
                    ))}
                </div>
                <div className="card-footer">
                    <div className='d-flex justify-content-between'>
                        <p className='text-muted'>{props.time}</p>
                        <Link to={`/patient-game-sessions/${props.gameid}/${props.userid}`} className='btn btn-outline-secondary btn-sm'>Detay</Link>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default GameCard