import {
    /** FETCH PROFESSIONS  */
    FETCH_GENESIS_FULFILLED,
    FETCH_GENESIS_REJECTED,
    FETCH_GENESIS_PENDING
} from "../../actions/auth/genesis";
const token = localStorage.getItem("accessToken");
const initialState = {
    done: false,
    error: {},
    spinner: false,
}
export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_GENESIS_PENDING:
            return {
                done: false,
                error: {},
                spinner: true,
            };
        case FETCH_GENESIS_FULFILLED:
            return {
                ...state,
                genesis: action.payload,
                done: true,
                error: {},
                spinner: false,
            };
        case FETCH_GENESIS_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        default:
            return state;
    }
};