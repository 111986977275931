import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GETSCOREBYGAMEID_FULFILLED = "FETCH_GETSCOREBYGAMEID_FULFILLED";
export const FETCH_GETSCOREBYGAMEID_REJECTED = "FETCH_GETSCOREBYGAMEID_REJECTED";
export const FETCH_GETSCOREBYGAMEID_PENDING = "FETCH_GETSCOREBYGAMEID_PENDING";

export function getScoreByGameId(gameId,userId) {
    const token = localStorage.getItem("accessToken")
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETSCOREBYGAMEID",
            payload: axios({
                method: "post",
                url: `${API_BASE}/api/get-score`,
                data: { gameId, userId },
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((result) => result.data)
        });
    };
}





