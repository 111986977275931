import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getEventsBySessionId } from '../../actions/game/getEventsBySessionId';
import Header from '../layout/header';

function SessionDetail(props) {
    const { userId } = useParams();
    const { gameId } = useParams();
    const { sessionId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getEventsBySessionId(userId, gameId, sessionId))
    }, [dispatch])
    return (
        <div>
            <Header />
            <div className="container mt-4">
                {props.getEventsBySessionId.done ?
                    props.getEventsBySessionId.getEventsBySessionId.result?.map((element, index) => {
                        return (
                            <div className="card mb-2">
                                <div className="card-header">
                                    <b>{element.EventName} :</b> {element.EventDetail}
                                </div>
                                <div className="card-body">

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Event Id</th>
                                                <th scope="col">Değer</th>
                                                <th scope="col">Oluşturulma Zamanı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                element.Scores?.map((element, index) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row">{index}</th>
                                                            <td>{element.EventId}</td>
                                                            <td>{element.Value}</td>
                                                            <td>{element.TimeStamp}</td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        )
                    })
                    : null}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);