import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getGameByUserId } from '../../actions/game/getGameByUserId';
import Header from '../layout/header';
import { useNavigate, useParams } from 'react-router';
import GameDetailCard from './gameDetailCard';
import { getSessions } from '../../actions/game/getSessions';
import { ConvertDate, FormatZuluTime } from '../../helpers/timeconverter';
import SessionCard from '../cards/SessionCard';
import LineChart from '../chart/LineChart';
import PieChart from '../chart/PieChart';


function PatientSessions(props) {
    const { GameId } = useParams();
    const { UserId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rightAnswers, setRightAnswers] = useState([]);
    const [wrongAnswers, setWrongAnswers] = useState([]);
    const [questionTimes, setQuestionTimes] = useState([]);
    const [timeFrame, setTimeFrame] = useState('monthly'); // weekly, monthly, yearly
    const [timeFrameForPie, setTimeFrameForPie] = useState('monthly')
    const [rightAnswersForPie, setRightAnswersForPie] = useState(0);
    const [wrongAnswersForPie, setWrongAnswersForPie] = useState(0);
    const [timePeriod, setTimePeriod] = useState('daily'); // daily, weekly, monthly, yearly
    const [filteredSessions, setFilteredSessions] = useState([]);



    useEffect(() => {
        dispatch(getSessions(UserId, GameId))
    }, [dispatch])

    useEffect(() => {
        if (props.getSessions.done) {
        }
    }, [props.getSessions])

    useEffect(() => {

        if (props.getSessions.done) {
            const today = new Date();
            let startDate;

            switch (timeFrame) {
                case 'weekly':
                    const dayOfWeek = today.getDay();
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
                    );
                    break;
                case 'monthly':
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        1
                    );
                    break;
                case 'yearly':
                    startDate = new Date(
                        today.getFullYear(),
                        0,
                        1
                    );
                    break;
                default:
                    // Varsayılan olarak haftalık veri
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1)
                    );
            }

            const dates = props.getSessions.getSessions.result.filter(session => {
                const sessionDate = new Date(session.CreatedAt);
                return sessionDate >= startDate; // Bu haftanın Pazartesi gününden itibaren oturumları filtrele
            })
            var newValues = dates.map(function (item) {
                return ConvertDate(item.CreatedAt);
            });
            setQuestionTimes(newValues)

        }

    }, [props.getSessions])


    useEffect(() => {
        if (props.getSessions.done) {
            const today = new Date();
            let startDate;

            switch (timeFrame) {
                case 'weekly':
                    const dayOfWeek = today.getDay();
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
                    );
                    break;
                case 'monthly':
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        1
                    );
                    break;
                case 'yearly':
                    startDate = new Date(
                        today.getFullYear(),
                        0,
                        1
                    );
                    break;
                default:
                    // Varsayılan olarak haftalık veri
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1)
                    );
            }

            const averages = props.getSessions.getSessions.result
                .filter(session => {
                    const sessionDate = new Date(session.CreatedAt);
                    return sessionDate >= startDate; // Bu haftanın Pazartesi gününden itibaren oturumları filtrele
                })
                .map(session => {
                    const scores = session.Scores.filter(score => score.EventId === 10006);
                    const sum = scores.reduce((accumulator, score) => {
                        const value = parseFloat(score.Value);
                        return accumulator + (isNaN(value) ? 0 : value);
                    }, 0);

                    const average = scores.length > 0 ? sum / scores.length : 0;
                    return {
                        SessionId: session.SessionId,
                        AverageValue: average
                    }; // Her oturumun ortalama skorunu döndür
                });

            var newValues = averages.map(function (item) {
                return item.AverageValue;
            });
            setWrongAnswers(newValues)

        }
    }, [props.getSessions]);
    useEffect(() => {
        if (props.getSessions.done) {
            const today = new Date();
            let startDate;

            switch (timeFrame) {
                case 'weekly':
                    const dayOfWeek = today.getDay();
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
                    );
                    break;
                case 'monthly':
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        1
                    );
                    break;
                case 'yearly':
                    startDate = new Date(
                        today.getFullYear(),
                        0,
                        1
                    );
                    break;
                default:
                    // Varsayılan olarak haftalık veri
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1)
                    );
            }

            const averages = props.getSessions.getSessions.result
                .filter(session => {
                    const sessionDate = new Date(session.CreatedAt);
                    return sessionDate >= startDate; // Bu haftanın Pazartesi gününden itibaren oturumları filtrele
                })
                .map(session => {
                    const scores = session.Scores.filter(score => score.EventId === 10005);
                    const sum = scores.reduce((accumulator, score) => {
                        const value = parseFloat(score.Value);
                        return accumulator + (isNaN(value) ? 0 : value);
                    }, 0);

                    const average = scores.length > 0 ? sum / scores.length : 0;
                    return {
                        SessionId: session.SessionId,
                        AverageValue: average
                    }; // Her oturumun ortalama skorunu döndür
                });

            var newValues = averages.map(function (item) {
                return item.AverageValue;
            });
            setRightAnswers(newValues)

        }
    }, [props.getSessions]);

    useEffect(() => {
        if (props.getSessions.done) {
            const today = new Date();
            let startDate;

            switch (timeFrameForPie) {
                case 'weekly':
                    const dayOfWeek = today.getDay();
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
                    );
                    break;
                case 'monthly':
                    startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                    break;
                case 'yearly':
                    startDate = new Date(today.getFullYear(), 0, 1);
                    break;
                default:
                    startDate = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1)
                    );
            }

            const totalCorrectAnswers = props.getSessions.getSessions.result.reduce((acc, session) => {
                const sessionDate = new Date(session.CreatedAt);
                if (sessionDate >= startDate) {
                    const correctAnswersCount = session.Scores.filter(score => score.EventId === 10014).reduce((acc, item) => acc + parseInt(item.Value || "0"), 0);
                    return acc + correctAnswersCount;
                }
                return acc;
            }, 0);

            const totalQuestions = props.getSessions.getSessions.result.reduce((acc, session) => {
                const sessionDate = new Date(session.CreatedAt);
                if (sessionDate >= startDate) {
                    const questionsCount = session.Scores.filter(score => score.EventId === 10013).reduce((acc, item) => acc + parseInt(item.Value || "0"), 0);
                    return acc + questionsCount;
                }
                return acc;
            }, 0);

            setRightAnswersForPie(totalCorrectAnswers);
            setWrongAnswersForPie(totalQuestions - totalCorrectAnswers); // Toplam soru sayısından doğru cevap sayısını çıkararak yanlış cevap sayısını buluyoruz
        }
    }, [props.getSessions, timeFrame]);


    function filterSessionsByTimePeriod(sessions = [], period) { // Varsayılan değer olarak boş bir dizi atandı
        const now = new Date();
        return sessions.filter(session => {
            const sessionDate = new Date(session.CreatedAt);
            switch (period) {
                case 'daily':
                    return sessionDate.toDateString() === now.toDateString();
                case 'weekly':
                    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
                    return sessionDate > oneWeekAgo;
                case 'monthly':
                    const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
                    return sessionDate > oneMonthAgo;
                case 'yearly':
                    const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
                    return sessionDate > oneYearAgo;
                default:
                    return true;
            }
        });
    }

    // Örnek kullanım: Günlük filtrelenmiş session'lar
    useEffect(() => {
        // filterSessionsByTimePeriod fonksiyonunu burada tanımla veya dışarıda tanımlayıp burada çağır
        const result = filterSessionsByTimePeriod(props.getSessions.getSessions?.result || [], timePeriod);
        setFilteredSessions(result); // Filtrelenmiş session'ları güncelle
      }, [timePeriod, props.getSessions.getSessions]);

    return (
        <>

            <Header />
            <div className='container'>
                <div className="row">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Oturum başlangıç zamanı</th>
                                <th scope="col">Tamamlanma durumu</th>
                                <th><button onClick={()=>setTimePeriod("daily")} className={`btn ${timePeriod === "daily" ? "btn-secondary" : "btn-outline-secondary"} btn-sm`}>Günlük</button></th>
                                <th><button onClick={()=>setTimePeriod("weekly")} className={`btn ${timePeriod === "weekly" ? "btn-secondary" : "btn-outline-secondary"} btn-sm`}>Haftalık</button></th>
                                <th><button onClick={()=>setTimePeriod("monthly")} className={`btn ${timePeriod === "monthly" ? "btn-secondary" : "btn-outline-secondary"} btn-sm`}>Monthly</button></th>
                                <th><button onClick={()=>setTimePeriod("yearly")} className={`btn ${timePeriod === "yearly" ? "btn-secondary" : "btn-outline-secondary"} btn-sm`}>Yearly</button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.getSessions.done && filteredSessions.map((element, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{FormatZuluTime(element.CreatedAt)}</td>
                                    <td>{element.Scores.map((score, scoreIndex) =>
                                        score.EventId === 10008 ? <button className='btn btn-outline-success btn-sm'>Başarılı</button> :
                                            score.EventId === 10009 ? <button className='btn btn-outline-danger btn-sm'>Erken tamamlandı</button> :
                                                null
                                    )}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className="card mt-2">
                            <div className="card-header">
                                <div className='float-start'>
                                    Kullanıcı oyun verisi
                                </div>
                                <div className='float-end'>
                                    <button
                                        onClick={() => {
                                            setTimeFrame("weekly")
                                            dispatch(getSessions(UserId, GameId))
                                        }}
                                        className={`btn btn-outline-secondary btn-sm me-2 ${timeFrame == "weekly" ? "active" : null}`}>Haftalık</button>
                                    <button
                                        onClick={() => {
                                            setTimeFrame("monthly")
                                            dispatch(getSessions(UserId, GameId))
                                        }}
                                        className={`btn btn-outline-secondary btn-sm me-2 ${timeFrame == "monthly" ? "active" : null}`}>Aylık</button>
                                    <button
                                        onClick={() => {
                                            setTimeFrame("yearly")
                                            dispatch(getSessions(UserId, GameId))
                                        }}
                                        className={`btn btn-outline-secondary btn-sm me-2 ${timeFrame == "yearly" ? "active" : null}`}>Yıllık</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <LineChart
                                    rightAnswers={rightAnswers}
                                    wrongAnswers={wrongAnswers}
                                    questionTimes={questionTimes} />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ">
                        <div className="card mt-2">
                            <div className="card-header">
                                <div className="float-start">
                                    Doğru yanlış genel istatistik
                                </div>
                                <div className='float-end'>
                                    <button
                                        onClick={() => {
                                            setTimeFrameForPie("weekly")
                                            dispatch(getSessions(UserId, GameId))
                                        }}
                                        className={`btn btn-outline-secondary btn-sm me-2 ${timeFrameForPie == "weekly" ? "active" : null}`}>Haftalık</button>
                                    <button
                                        onClick={() => {
                                            setTimeFrameForPie("monthly")
                                            dispatch(getSessions(UserId, GameId))
                                        }}
                                        className={`btn btn-outline-secondary btn-sm me-2 ${timeFrameForPie == "monthly" ? "active" : null}`}>Aylık</button>
                                    <button
                                        onClick={() => {
                                            setTimeFrameForPie("yearly")
                                            dispatch(getSessions(UserId, GameId))
                                        }}
                                        className={`btn btn-outline-secondary btn-sm me-2 ${timeFrameForPie == "yearly" ? "active" : null}`}>Yıllık</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <PieChart wrongAnswers={wrongAnswersForPie} rightAnswers={rightAnswersForPie} />
                            </div>
                        </div>
                    </div>
                    {props.getSessions.done ?
                        props.getSessions.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            props.getSessions.getSessions.result.map((element, index) =>
                                <SessionCard
                                    key={index}
                                    sessionid={element.SessionId}
                                    createdat={element.CreatedAt}
                                    gameid={GameId}
                                    userid={UserId}
                                />
                            )
                        :
                        props.getSessions.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            <div className="alert alert-danger mt-4" role="alert">
                                <h4 className="alert-heading">Üzgünüz!</h4>
                                <p>Bu hasta henüz oyunlardan herhangi birini oynamamıştır.</p>
                                <hr />
                                <p className="mb-0">Bir yanlışlık olduğunu düşünüyorsanız lütfen <a href="https://www.guvenfuture.com/">bizimle iletişime geçiniz.</a></p>
                            </div>
                    }
                    {/* {
                        props.getAssignment.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            : null
                    } */}


                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientSessions);
