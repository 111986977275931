import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GENESIS_FULFILLED = "FETCH_GENESIS_FULFILLED";
export const FETCH_GENESIS_REJECTED = "FETCH_GENESIS_REJECTED";
export const FETCH_GENESIS_PENDING = "FETCH_GENESIS_PENDING";
export const LOGOUT = "LOGOUT";

export function genesis(phoneNumber) {
    localStorage.setItem("phoneNumber", phoneNumber)
    return (dispatch) => {
        dispatch({
            type: "FETCH_GENESIS",
            payload: axios({
                method: "post",
                url: `${API_BASE}/api/genesis`,
                data: { phoneNumber },
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                },
            }).then((result) => result.data)
        });
    };
}





