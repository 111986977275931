import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Header from '../layout/header';
import { getGameByUserId } from '../../actions/game/getGameByUserId';
import GameCard from '../cards/GameCard';
import { ConvertDate } from '../../helpers/timeconverter';
import { getHistoryExport } from '../../actions/game/getHistoryExport'; 

function PatientGames(props) {

    const { Id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    function convertDateFormat(dateStr) {
        // Tarih objesi oluştur
        const dateObj = new Date(dateStr);
      
        // Tarih bileşenlerini al
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başladığı için 1 ekliyoruz
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const seconds = dateObj.getSeconds().toString().padStart(2, '0');
      
        // 12 saatlik biçime çevir
        const hourFormatted = hours % 12 || 12; // 0'ı 12'ye çevir
        const amPm = hours < 12 ? 'AM' : 'PM';
      
        // Yeni formatı oluştur
        const formattedDateStr = `${month}/${day}/${year} ${hourFormatted}:${minutes}:${seconds} ${amPm}`;
      
        return formattedDateStr;
      }

    useEffect(() => {
        
        dispatch(getGameByUserId(Id))
    
    }, [dispatch]);

    console.log(startDate)

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="d-flex align-items-center mt-4">
                        <button
                            onClick={()=> {
                                dispatch(getHistoryExport(Id, convertDateFormat(startDate), convertDateFormat(endDate)));
                            }}
                            className='btn btn-outline-success btn-sm'>Excel Export</button>
                        <input 
                        onChange={(e) => setStartDate(e.target.value)}
                        type="datetime-local" className="form-control ms-2 me-2" id="exampleFormControlInput1" />
                        <input 
                        onChange={(e) => setEndDate(e.target.value)}
                        type="datetime-local" className="form-control ms-2 me-2" id="exampleFormControlInput1" />
                    </div>
                    {props.getGameByUserId.done ?
                        props.getGameByUserId.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            props.getGameByUserId.getGameByUserId.result.map((element, index) =>
                                <GameCard
                                    key={index}
                                    name={element.Name}
                                    tags={element.Tag}
                                    detail={element.Detail}
                                    gameid={element.Id}
                                    userid={Id}
                                    time={ConvertDate(element.CreatedAt)}
                                />
                            )
                        :
                        props.getGameByUserId.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            <div className="alert alert-danger mt-4" role="alert">
                                <h4 className="alert-heading">Üzgünüz!</h4>
                                <p>Bu hasta henüz oyunlardan herhangi birini oynamamıştır.</p>
                                <hr />
                                <p className="mb-0">Bir yanlışlık olduğunu düşünüyorsanız lütfen <a href="https://www.guvenfuture.com/">bizimle iletişime geçiniz.</a></p>
                            </div>
                    }
                    {/* {
                        props.getAssignment.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            : null
                    } */}


                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientGames);
