import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GETASSIGNMENT_FULFILLED = "FETCH_GETASSIGNMENT_FULFILLED";
export const FETCH_GETASSIGNMENT_REJECTED = "FETCH_GETASSIGNMENT_REJECTED";
export const FETCH_GETASSIGNMENT_PENDING = "FETCH_GETASSIGNMENT_PENDING";

export function getAssignment() {
    const token = localStorage.getItem("accessToken")
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETASSIGNMENT",
            payload: axios({
                method: "get",
                url: `${API_BASE}/api/assignment/get`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((result) => result.data)
        });
    };
}





