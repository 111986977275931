import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ rightAnswers, wrongAnswers, questionTimes }) => {
    const data = {
        labels: questionTimes,
        datasets: [
            {
                label: 'Doğru Cevap Süreleri',
                data: rightAnswers,
                fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
            },
            {
                label: 'Yanlış Cevap Süreleri',
                data: wrongAnswers,
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    return <Line data={data} />;
};

export default LineChart;