import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import Header from '../layout/header';
import { useParams } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { getScoreByGameId } from '../../actions/game/getScoreByGameId';
import { getGameByUserId } from '../../actions/game/getGameByUserId';





function GameDetailTK(props) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const { Id, UserId, Name } = useParams();
    const dispatch = useDispatch();




    useEffect(() => {
        dispatch(getScoreByGameId(Id, UserId))
    }, [dispatch])

    useEffect(() => {
        dispatch(getGameByUserId(UserId))
    }, [dispatch])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: Name ? Name : "Oyun Adı"
            },
        },
    };

    if(props.getScoreByGameId.done){
        console.log(props.getScoreByGameId)
    }
    // props.getScoreByGameId.getScoreByGameId.map((element,index) => element.Correct )
    // console.log(props.getScoreByGameId.getScoreByGameId)
    const labels = props.getScoreByGameId.done && props.getGameByUserId ? props.getScoreByGameId.getScoreByGameId.map((element, index) => `Oynama süresi:`+ element.PlayTime) : ["1", "2"]
 
    // const labels = props.score && props.score.time ? props.score.time : ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const data = {
        labels,
        datasets: [
            {
                fill: false,
                label: 'Doğru Cevap',
                data: props.getScoreByGameId.done ? props.getScoreByGameId.getScoreByGameId.map((element, index) => element.Correct) : faker.datatype.number({ min: 0, max: 1000 }),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                fill: false,
                label: 'Yanlış Cevap',
                data: props.getScoreByGameId.done ? props.getScoreByGameId.getScoreByGameId.map((element, index) => element.Wrong) : faker.datatype.number({ min: 0, max: 1000 }),
                borderColor: 'rgb(255, 68, 68)',
                backgroundColor: 'rgba(255, 68, 68, 0.5)',
            },
        ],
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="col-10">
                        <h3 className='mt-2 mb-2'>{Name ? Name : "Oyun Adı"}</h3>
                        {props.getScoreByGameId.done && props.getGameByUserId.done ?
                            <Line options={options} data={data} />
                            : null}
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GameDetailTK);
