import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const PieChart = ({ rightAnswers, wrongAnswers }) => {
    const data = {
        labels: ['Doğru Cevaplar', 'Yanlış Cevaplar'],
        datasets: [
            {
                label: 'Cevap Dağılımı',
                data: [rightAnswers, wrongAnswers],
                backgroundColor: [
                    'rgb(54, 162, 235)',
                    'rgb(255, 99, 132)'
                ],
                hoverOffset: 4
            }
        ],
    };

    return <Pie data={data} />;
};

export default PieChart;
