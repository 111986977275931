import {
    /** FETCH PROFESSIONS  */
    FETCH_VERIFYCODE_FULFILLED,
    FETCH_VERIFYCODE_REJECTED,
    FETCH_VERIFYCODE_PENDING
} from "../../actions/auth/verifyCode";
const initialState = {
    done: false,
    error: {},
    spinner: false,
}
export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_VERIFYCODE_PENDING:
            return {
                done: false,
                error: {},
                spinner: true,
            };
        case FETCH_VERIFYCODE_FULFILLED:
            return {
                ...state,
                verifyCode: action.payload,
                done: true,
                error: {},
                spinner: false,
            };
        case FETCH_VERIFYCODE_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        default:
            return state;
    }
};