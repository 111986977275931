import { combineReducers } from "redux";
import login from "./auth/login"
import getAssignment from "./assignment/getAssignment";
import getGameByUserId from "./game/getGameByUserId";
import genesis from "./auth/genesis";
import getScoreByGameId from "./game/getScoreByGameId";
import verifyCode from "./auth/verifyCode";
import getSessions from "./game/getSessions";
import getEventsBySessionId from "./game/getEventsBySessionId";
export default combineReducers({
    login,
    getAssignment,
    getGameByUserId,
    genesis,
    getScoreByGameId,
    verifyCode,
    getSessions,
    getEventsBySessionId
    
});