import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import PatientStatistic from '../dashboard/patientStatistic'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ConvertDate } from '../../helpers/timeconverter';

function SessionCard(props) {
    return (
        <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3'>
            <div className="card">
                <div className="card-header">
                    {props.sessionid}
                </div>
                <div className="card-body">
                    <p>Oturum numarası {props.sessionid}.</p>
                    <hr />
                </div>
                <div className="card-footer">
                    <div className='d-flex justify-content-between'>
                        <p className='text-muted'>{ConvertDate(props.createdat)}</p>
                        <Link to={`/game-session-detail/${props.userid}/${props.gameid}/${props.sessionid}`} className='btn btn-outline-secondary btn-sm'>Detay görüntüle</Link>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default SessionCard
