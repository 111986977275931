import React from 'react'
import { Link } from 'react-router-dom'

function gameDetailCard(props) {
  console.log(props)
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{props.game && props.game.Name ? props.game.Name : "Oyun ismi getirilemedi"}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{props.game && props.game.Detail ? props.game.Detail : "Oyun detay bilgisi bulunmamakta"}</h6>
          <div class="d-flex justify-content-end">
            <Link to={`/game-statistic-detail/${props.game.Id}/${props.userId}/${props.game.Name}`} className='btn btn-outline-success btn-sm'>Skor Detayları</Link>
          </div>

        </div>
      </div>
    </div>
  )
}

export default gameDetailCard