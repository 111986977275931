import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PatientCard from '../cards/patientcard';
import { getAssignment } from "../../actions/assignment/getAssignment"
import Header from '../layout/header';

function Index(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAssignment())
    }, [dispatch])
    

    return (
        <>
            <Header />


            <div className="container">
                <div className="row">
                    {props.getAssignment.done ?
                        props.getAssignment.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            props.getAssignment.assignment.result.map((element, index) =>
                                <PatientCard
                                    key={index}
                                    id={`${element.User.Id}`}
                                    name={`${element.User.Name} ${element.User.LastName}`}
                                    level="2"
                                    email={`${element.User.Email}`}
                                    phone={`${element.User.PhoneNumber}`}
                                />
                            )
                        :
                        props.getAssignment.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            :
                            <div className="alert alert-danger mt-4" role="alert">
                                <h4 className="alert-heading">Üzgünüz!</h4>
                                <p>Henüz size atanmış bir hasta bulunmamaktadır bu alanda yalnızca ataması sizin tarafınıza yapılmış hastaları görüntüleyebilirsiniz.</p>
                                <hr />
                                <p className="mb-0">Bir yanlışlık olduğunu düşünüyorsanız lütfen <a href="https://www.guvenfuture.com/">bizimle iletişime geçiniz.</a></p>
                            </div>
                    }
                    {/* {
                        props.getAssignment.spinner ?
                            <div className="d-flex align-items-center mt-4">
                                <strong>Yükleniyor...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            : null
                    } */}


                </div>
            </div>


        </>
    )
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Index);


