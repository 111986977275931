import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GETEVENTSBYSESSIONID_FULFILLED = "FETCH_GETEVENTSBYSESSIONID_FULFILLED";
export const FETCH_GETEVENTSBYSESSIONID_REJECTED = "FETCH_GETEVENTSBYSESSIONID_REJECTED";
export const FETCH_GETEVENTSBYSESSIONID_PENDING = "FETCH_GETEVENTSBYSESSIONID_PENDING";

export function getEventsBySessionId(userId,gameId,sessionId) {
    const token = localStorage.getItem("accessToken")
    return (dispatch) => {
        dispatch({
            type: "FETCH_GETEVENTSBYSESSIONID",
            payload: axios({
                method: "get",
                url: `${API_BASE}/api/user/${userId}/game/${gameId}/session/${sessionId}`,
                data: { userId },
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }).then((result) => result.data)
        });
    };
}





