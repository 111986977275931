import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_GETHISTORYEXPORT_FULFILLED = "FETCH_GETHISTORYEXPORT_FULFILLED";
export const FETCH_GETHISTORYEXPORT_REJECTED = "FETCH_GETHISTORYEXPORT_REJECTED";
export const FETCH_GETHISTORYEXPORT_PENDING = "FETCH_GETHISTORYEXPORT_PENDING";

export function getHistoryExport(userId, startDate, endDate) {
    const token = localStorage.getItem("accessToken");

    return (dispatch) => {
        dispatch({
            type: FETCH_GETHISTORYEXPORT_PENDING,
        });

        axios({
            method: "POST",
            url: `${API_BASE}/api/gethistory?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
            responseType: 'blob', // Bu satırı ekleyin
            headers: {
                "Accept-Language": "tr-tr",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            // Blob'u işleyin ve bir dosya olarak indirin
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.xlsx'); // İndirilecek dosyanın adını ayarlayın
            document.body.appendChild(link);
            link.click();

            // İndirme işlemi tamamlandıktan sonra linki ve URL'yi temizleyin
            link.remove();
            window.URL.revokeObjectURL(url);

            dispatch({ type: FETCH_GETHISTORYEXPORT_FULFILLED });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_GETHISTORYEXPORT_REJECTED,
                payload: error
            });
        });
    };
}


export function resetGetHistoryExport() {
    return (dispatch) => {
        dispatch({
            type: "RESET_GETHISTORYEXPORT",
            payload: null
        });
    };
}


