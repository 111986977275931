import { API_BASE } from "../../config/env";
import axios from "axios";
export const FETCH_LOGIN_FULFILLED = "FETCH_LOGIN_FULFILLED";
export const FETCH_LOGIN_REJECTED = "FETCH_LOGIN_REJECTED";
export const FETCH_LOGIN_PENDING = "FETCH_LOGIN_PENDING";
export const LOGOUT = "LOGOUT";

export function login(phoneNumber, password) {
    localStorage.setItem("phoneNumber", phoneNumber)
    return (dispatch) => {
        dispatch({
            type: "FETCH_LOGIN",
            payload: axios({
                method: "post",
                url: `${API_BASE}/api/login`,
                data: { phoneNumber, password },
                headers: {
                    "Accept-Language": "tr-tr",
                    "Content-Type": "application/json",
                },
            }).then((result) => result.data)
        });
    };
}

export function logOut() {
    sessionStorage.clear();
    localStorage.clear();

    return (dispatch) => {
        dispatch({
            type: "LOGOUT",
        });
    };
}



