import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css'
import { login } from '../../actions/auth/login';



function Login(props) {
    const [mounted, setMounted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");

    console.log(phoneNumber)

    function handleSubmit(event) {
        event.preventDefault();
        dispatch(login(phoneNumber, password))
    }

    if (props.login.isLogin === true) {
        navigate("/");
    }
    useEffect(() => {
        if (mounted && props.login.done === false) {
            if (Object.keys(props.login.error).length !== 0) {
                if (props.login.error.response.data) {
                    Swal.fire({
                        confirmButtonText: 'Anladım',
                        icon: 'error',
                        title: 'Oops...',
                        text: props.login.error.response.data.errorMessage,
                    })
                } else {
                    Swal.fire({
                        confirmButtonText: 'Anladım',
                        icon: 'error',
                        title: 'Oops...',
                        text: props.login.error.response.data.errorMessage,
                    })
                }

            }
        }
        setMounted(true);
    }, [props.login])


    return (
        <div className='row mt-5'>
            <div className="d-flex justify-content-center">
                <div className="col-5">
                    <h1 className='text-primary text-start'>Giriş yap</h1>
                    <br />
                    <form onSubmit={handleSubmit} className="input-group">
                        <label htmlFor="basic-url" className="form-label">Telefon Numarası</label>
                        <div className="input-group mb-3">
                            <PhoneInput
                                containerStyle={{ width: "100% !important;" }}
                                inputStyle={{ width: `100% !important;`, paddingTop: "18px", paddingBottom: "18px", border: `0px !important;`, paddingLeft: `58px !important` }}
                                labe
                                required
                                id="phoneNumber"
                                className=""
                                type="text"
                                placeholder="Telefon numaranız"
                                name="phoneNumber"
                                country={"tr"}
                                enableSearch={false}
                                value={phoneNumber}
                                onChange={(e) => {
                                    setPhoneNumber(e)
                                }}
                            />
                        </div>
                        <label htmlFor="password" className="form-label">Şifre</label>
                        <div className="input-group mb-3">
                            <input
                                type="password"
                                placeholder="Şifre"
                                className="form-control"
                                onChange={(e) => { setPassword(e.target.value) }}
                                value={password}
                                id="password"
                                aria-describedby="basic-addon3" />
                        </div>
                        {/* <div className='row w-100'>
                            <div className="col d-flex justify-content-end">
                                <Link
                                    style={{ zIndex: 0 }}
                                    to="/forgot-password" className='text-primary'>Şifremi Unuttum</Link>
                            </div>
                        </div> */}
                        <div className='d-grid gap-2 w-100 mt-4'>
                            {props.login.spinner ?
                                <button type='submit' className='btn btn-primary' disabled>
                                    <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true'></span>
                                    Giriş Yapılıyor
                                </button>
                                :
                                <button type='submit' className='btn btn-primary'>Giriş Yap</button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);