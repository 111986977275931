import {
    /** FETCH PROFESSIONS  */
    FETCH_GETEVENTSBYSESSIONID_FULFILLED,
    FETCH_GETEVENTSBYSESSIONID_REJECTED,
    FETCH_GETEVENTSBYSESSIONID_PENDING,
} from "../../actions/game/getEventsBySessionId";
const initialState =
{
    done: false,
    error: {},
    spinner: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GETEVENTSBYSESSIONID_PENDING:
            return {
                done: false,
                error: {},
                spinner: true,
            };
        case FETCH_GETEVENTSBYSESSIONID_FULFILLED:
            return {
                ...state,
                getEventsBySessionId: action.payload,
                done: true,
                error: {},
                spinner: false,
            };
        case FETCH_GETEVENTSBYSESSIONID_REJECTED:
            return {
                ...state,
                error: action.payload,
                done: false,
                spinner: false,
            };
        default:
            return state;
    }
};